import blueGrey from '@material-ui/core/colors/blueGrey'

export const drawerWidth = 240
export const drawerClosedWidth = 60
export const drawerHiddenWidth = 0

const ApplicationStyles = theme => ({
  loadingBar: {
    zIndex: 2000,
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0
  },
  navBarRoot: {
    backgroundColor: theme.palette.common.white,
    color: blueGrey['900'],
  },
  root: {
    width: '100%',
    height: '100%',
    marginTop: 0,
    zIndex: 1,
    overflow: 'hidden'
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
  },
  toolsLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 24
  },
  toolsRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: 24
  },
  content: {
    width: '100%',
    overflow: 'auto',
    position: 'relative',
    flexGrow: 1,
    backgroundColor: theme.palette.grey['200'],
    padding: 24,
    height: 'calc(100% - 110px)',
    marginTop: 64
  },
  messageStyle: {
    background: theme.palette.secondary.main,
    color: theme.palette.white
  }
})

export default ApplicationStyles
