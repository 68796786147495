import React from 'react';
import { Button, Grid, makeStyles, Modal } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  modal: {
    background: theme.palette.white,
    position: 'absolute',
    margin: 'auto',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '320px',
    height: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    borderRadius: 20
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    fontSize: 12,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    textAlign: 'justify'
  },
  itemButton: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2)
  },
  tituloTaxa: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  labelTaxa: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.primary.main,
    fontSize: 10,
    padding: theme.spacing(0.5),
  },
  taxa: {
    color: theme.palette.primary.main,
    fontSize: 10,
    padding: theme.spacing(0.5),
  },
}));


export default (props) => {
  
  const classes = useStyles();

  const { modalOpen, setModalOpen } = props;

  return <Modal
    open={modalOpen}
    onClose={() => setModalOpen(false)}
  >
    <div className={classes.modal}>
      <Grid container>
        <Grid item xs={12} className={classes.item}>
          {`O Saldo Disponível é o valor já compensado, disponível na operadora.`}
        </Grid>
        <Grid item xs={12} className={classes.item}>
          {`Este valor é transferido automaticamente para a conta configurada semanalmente às sextas.`}
        </Grid>
        <Grid item xs={12} className={classes.item}>
          {"O Saldo a Receber corresponde ao valor recebido cujos pagamentos ainda não foram compensados."}
        </Grid>
        <Grid item xs={12} className={classes.item}>
          {"O Valor Antecipável mostra a faixa de valores disponíveis para antecipação."}
        </Grid>
        <Grid item xs={12} className={classes.itemButton}>
          <Button 
            onClick={() => setModalOpen(false)}
            color="primary"
            variant="outlined"
            size="small"
          >
            {"FECHAR"}
          </Button>
        </Grid>
      </Grid>
    </div>
  </Modal>
}