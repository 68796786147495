import React, { useContext, useState } from 'react'
import { Button, Grid } from '@material-ui/core';
import { TextField } from '../../Components/Fields';
import { Field, Form } from 'react-final-form';
import api from '../../services/apiCliente';
import { ClienteContext } from './ClienteAuthProvider';
import Fetching from '../utils/Fetching';

export default (props) => {
  const { classes, setErro, onForgetPasswordClick, formRef, onBackClick, onLoginSuccess } = props;
  const { login } = useContext(ClienteContext);

  const [fetchingLogin, setFetchingLogin] = useState(false);

  const validate = values => ({
    email: !values.email ? "Obrigatório" : undefined,
    password: !values.password ? "Obrigatório" : undefined,
  });

  const handleLogin = (values) => {
    setFetchingLogin(true);
    api.login(values).then(response => {
      if (response.ok) {
        if (!response.data.accessToken) {
          setErro("Erro ao validar token")
        } else {
          login(response.data);
          api.setToken(response.data.accessToken);
          onLoginSuccess();
        }
      } else {
        setErro("Login Inválido")
      }

      setFetchingLogin(false);
    })
  } 

  return <>
    <Form
      onSubmit={handleLogin}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container className={classes.formContainer} ref={formRef}>
            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                className={classes.field}
                component={TextField}
                name="email"
                label="E-mail"
              />
            </Grid>
            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                className={classes.field}
                component={TextField}
                label={"Senha"}
                type='password'
                name='password'/>
            </Grid>
            <Grid item xs={12} className={classes.spanContainer}>
              <span onClick={onForgetPasswordClick} className={classes.spanPrimaryText}>{"Esqueci minha senha"}</span>
            </Grid>
            <Grid item xs={12} className={classes.submitContainer}>
              <Fetching isFetching={fetchingLogin}>
                <Button
                  variant="outlined"
                  className={classes.backButton}
                  onClick={onBackClick}
                >
                  {"VOLTAR"}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.loginButton}
                >
                  {"ENTRAR"}
                </Button>
              </Fetching>
            </Grid>
          </Grid>
        </form>)} />
  </>
}