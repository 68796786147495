import { createMuiTheme } from '@material-ui/core/styles';

export const defaultColors = {
  white: "white",
  red: "#ff7a7a",
  light: "#f8f8f8",
  strongRed: "#d10000"
}

export const defaultPrimaryColor = '#3f3838';

export const defaultSecondaryColor = '#6a695f';

export default createMuiTheme({
  palette: {
    primary: {
      main: defaultPrimaryColor
    },
    secondary: {
      main: defaultSecondaryColor
    },
    ...defaultColors
  },
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#505050"
      }
    }
  }
})
