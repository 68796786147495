import { restApi } from '../../services/api';

export const getSaldos = async () => {
  return restApi.get('saldos');
}

export const getRecebiveis = async (limit, skip) => {
  return restApi.get(`recebiveis?$limit=${limit}&$skip=${skip}`);
}

export const getAntecipacoes = async () => {
  return restApi.get('antecipacoes');
}

export const getTransferencias = async (limit, skip) => {
  return restApi.get(`transferencias?$limit=${limit}&$skip=${skip}`);
}

export const addAntecipacao = async (valor) => {
  return restApi.post('antecipacoes', {
    valor: parseInt(valor * 100)
  });
}

export const addTransferencia = async (valor) => {
  return restApi.post('transferencias', {
    valor: parseInt(valor * 100)
  });
}
