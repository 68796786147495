import React, { useState } from 'react';
import { Toolbar } from '@devexpress/dx-react-scheduler-material-ui';
import { Box, Button, Grid, MenuItem, MenuList, Popover, isWidthDown, makeStyles } from '@material-ui/core';
import { UnfoldMore, UnfoldLess, Add, DateRange, Refresh } from '@material-ui/icons';
import OcupacaoModal from './OcupacaoModal';
import HorarioSemanalModal from './HorarioSemanalModal';
import DiaOcupadoModal from './DiaOcupadoModal';

const useStyles = makeStyles(theme => ({
  toolbarFlexibleSpace: {
    flex: 'auto',
    display: 'flex',
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: theme.spacing(2),
  },
  acoes: {
    paddingBottom: theme.spacing(2),
  },
  button: {
    fontWeight: 'bold'
  },
  icon: {
    width: '18px',
    marginRight: 5
  },
  collapseButtonContainer: {
    cursor: 'pointer',
  },
  collapseIcon: {
    color: theme.palette.primary.main,
    paddingRight: theme.spacing(1),
    height: '100%',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  title: {
    color: theme.palette.primary.main
  },
  description: {
    color: theme.palette.primary.main,
    fontSize: 12,
    whiteSpace: 'normal',
    textAlign: 'justify',
    marginTop: 4
  }
}));

export default (props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const { width, setView, espacoSelecionado, versionOcupacoes, setVersionOcupacoes} = props;
    
  const shouldUseCollapse = espacoSelecionado && isWidthDown('xs', width);
  const shouldShowAllActions = !shouldUseCollapse || collapseOpen;

  const [ocupacaoModalOpen, setOcupacaoModalOpen] = useState(false);
  const [horarioSemanalModalOpen, setHorarioSemanalModalOpen] = useState(false);
  const [diaOcupadoModalOpen, setDiaOcupadoModalOpen] = useState(false);
  
  const classes = useStyles();

  const handleClickPopover = event => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  }

  const handleClosePopover = () => {
    setMenuOpen(false)
    setAnchorEl(null)
  }
  
  return <Toolbar.FlexibleSpace className={classes.toolbarFlexibleSpace}>

    {espacoSelecionado && <Grid container className={classes.toolbarContainer}>
      <Grid item className={classes.acoes}>
        <Button
          className={classes.button}
          color="primary"
          size="small"
          onClick={() => setVersionOcupacoes(versionOcupacoes + 1)}
        >
          <Refresh className={classes.icon} />
          <span>{"ATUALIZAR"}</span>
        </Button>
      </Grid>
      {shouldShowAllActions && <Grid item className={classes.acoes}>
        <Button
          color="primary"
          size="small"
          onClick={() => setView("horarios")}
        >
          <DateRange className={classes.icon} />
          <span>{"ALTERAR HORÁRIOS DISPONÍVEIS"}</span>
        </Button>
      </Grid>}
      {shouldShowAllActions && <Grid item className={classes.acoes}>
        <Button
          color="primary"
          size="small"
          onClick={event => {
            handleClickPopover(event);
          }}
        >
          <Add className={classes.icon} />
          <span>{"ADICIONAR"}</span>
        </Button>
      </Grid>}
    </Grid>}

    {shouldUseCollapse && <div
      className={classes.collapseButtonContainer}
      onClick={() => setCollapseOpen(!collapseOpen)}
    >
      {collapseOpen && <UnfoldLess className={classes.collapseIcon}/>}
      {!collapseOpen && <UnfoldMore className={classes.collapseIcon}/>}
    </div>}

    <Popover
      anchorEl={anchorEl}
      keepMounted
      open={menuOpen}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuList dense={true}>
        <MenuItem
          onClick={() => {
            handleClosePopover();
            setOcupacaoModalOpen(true)
          }}
        >
          <Box display='flex' flexDirection='column' maxWidth={300}>
            <span className={classes.title}><strong>Horário Ocupado</strong></span>
            <span className={classes.description}>
              Adicionar um horário ocupado por uma reserva
              realizada fora do sistema ou um horário que o espaço  
              ficará indisponível
            </span>
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClosePopover();
            setHorarioSemanalModalOpen(true)
          }}
        >
          <Box display='flex' flexDirection='column' maxWidth={300}>
            <span className={classes.title}><strong>Horário Semanal</strong></span>
            <span className={classes.description}>
              Adicionar um horário fixo que estará ocupado todas as semanas
            </span>
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClosePopover();
            setDiaOcupadoModalOpen(true)
          }}
        >
          <Box display='flex' flexDirection='column' maxWidth={300}>
            <span className={classes.title}><strong>Dia Ocupado</strong></span>
            <span className={classes.description}>
              Remover todos os horários de uma data para um espaço ou todos os espaços
            </span>
          </Box>
        </MenuItem>
      </MenuList>
    </Popover>

    <OcupacaoModal 
      modalOpen={ocupacaoModalOpen} 
      setModalOpen={setOcupacaoModalOpen} 
      {...props}
    />

    <HorarioSemanalModal 
      modalOpen={horarioSemanalModalOpen} 
      setModalOpen={setHorarioSemanalModalOpen} 
      {...props}
    />

    <DiaOcupadoModal 
      modalOpen={diaOcupadoModalOpen} 
      setModalOpen={setDiaOcupadoModalOpen} 
      {...props}
    />

  </Toolbar.FlexibleSpace>
}