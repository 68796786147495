import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import FormHelperText from '@material-ui/core/FormHelperText'
import MaskedInput from 'react-text-mask'

const maskFunction = (value) => {
  if (value && value.replace(/\D/g,'').length > 11) {
    return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  }
  return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
}

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={maskFunction}
    />
  );
}

export default function CPFCNPJField(props) {
  const {
    input: {name, onChange, value, onBlur},
    meta,
    label,
    ...rest
  } = props;

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <FormControl
      fullWidth={props.fullWidth}
      error={meta.error && meta.touched} >
      <InputLabel
        htmlFor={name}
      >
        {label}
      </InputLabel>
      <Input
        {...rest}
        onBlur={onBlur}
        name={name}
        error={showError}
        value={value}
        aria-describedby="helper-text"
        onChange={onChange}
        inputComponent={TextMaskCustom}
      />
      {showError ? <FormHelperText error={showError} id="helper-text">{meta.error}</FormHelperText> : null}
      </FormControl>
  );
}

