import React, { useContext, useState } from 'react';
import { Grid, makeStyles} from '@material-ui/core';
import { FileCopy, Launch } from '@material-ui/icons';
import { AuthContext } from '../../Components/Login';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '../../Components/Common/Snackbar';

const useStyles = makeStyles(theme => ({
  linkContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  linkLabel: {
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 'bold'
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: 12,
    marginLeft: 5,
  },
  linkIcon: {
    color: theme.palette.secondary.main,
    width: '18px',
    marginLeft: theme.spacing(1),
    cursor: 'pointer'
  }
}));


export default () => {

  const classes = useStyles();

  const [message, setMessage] = useState();

  const authContext = useContext(AuthContext);
  const { url } = authContext.user;
  const publicUrl = window.location.origin + '/em/' + url;

  return <>
    <Grid container className={classes.linkContainer}>
      <Grid item>
        <span className={classes.linkLabel}>{"Link para reservas:"}</span>
      </Grid>
      <Grid item>
        <span className={classes.link}>{publicUrl}</span>
      </Grid>
      <Grid item>
        <CopyToClipboard text={publicUrl} onCopy={() => setMessage("Link copiado para área de transferência")}>
          <FileCopy className={classes.linkIcon}/>
        </CopyToClipboard>
        <a href={publicUrl} target="_blank" rel="noopener noreferrer">
          <Launch className={classes.linkIcon}/>
        </a>
      </Grid>
    </Grid>
    <Snackbar message={message} onClose={() => setMessage(undefined)} />
  </>
}