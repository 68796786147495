import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './AppWrapStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import UserAvatar from './UserAvatar'

const useStyles = makeStyles(styles)

const AppWrap = ({
  logo,
  children,
  onLogout,
  classes: appWrapClasses,
  userAvatarProps,
  customBar,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <AppBar
          className={classNames(classes.appBar)}
          position='absolute'
        >
          <Toolbar disableGutters classes={{ root: classes.navBarRoot }}>
            <Grid container>
              <Grid item xs className={classNames(classes.toolsLeft)}>
                {logo}
              </Grid>

              {customBar && <Grid item xs className={classNames(classes.toolsRight)}>{customBar}</Grid>}

              <Grid item className={classNames(classes.toolsRight)}>
                <UserAvatar onLogout={onLogout} {...userAvatarProps} />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <main
          className={classNames(
            classes.content,
            appWrapClasses && appWrapClasses.content
          )}
        >
          {children}
        </main>
      </div>
    </div>
  )
}

AppWrap.propTypes = {
  /** Logo Picture */
  logo: PropTypes.any,
  /** Function for handle the logout */
  onLogout: PropTypes.func,
  /** User avatar props */
  userAvatarProps: PropTypes.object,
  /** Custom options to display after breadcrumbs */
  customBar: PropTypes.any,
}

export default AppWrap
