import { emailValido } from "../../utils/utils";
import validatePassword from "../login/validationPassword";

const estados = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO'
];

export const validateFields = values => ({
  numero_cartao: validateNumber(values.numero_cartao, 16),
  nome_cartao: !values.nome_cartao ? "Campo Obrigatório" : undefined,
  data_expiracao: validateDataExpiracao(values.data_expiracao),
  cvv: validateNumber(values.cvv, 3),
  documento: validateNumber(values.documento, 11),
  ddd: validateNumber(values.ddd, 2),
  telefone: !values.telefone ? "Campo Obrigatório" : undefined,
  email: validateEmail(values.email),
  cep: validateNumber(values.cep, 8),
  logradouro: !values.logradouro ? "Campo Obrigatório" : undefined,
  numero: !values.numero ? "Campo Obrigatório" : undefined,
  bairro: !values.bairro ? "Campo Obrigatório" : undefined,
  cidade: !values.cidade ? "Campo Obrigatório" : undefined,
  estado: validateEstado(values.estado),
  password: values.criar_conta ? validatePassword(values.password) : undefined,
  password_confirm: values.criar_conta && (values.password !== values.password_confirm) ? "Senhas diferem" : undefined,
});

const validateNumber = (value, size) => {
  if (!value) return "Campo obrigatório";
  if (value.replace(/\D/g,'').length !== size) return "Formato inválido";
  return undefined;
}

const validateDataExpiracao = (value) => {
  if (!value) return "Campo obrigatório";
  const data = value.replace(/\D/g,'');
  if (data.length !== 4) return "Formato inválido";
  const mes = parseInt(data.substring(0, 2));
  if (mes < 1 || mes > 12) return "Formato inválido"
  return undefined;
}

const validateEstado = (value) => {
  if (!value) return "Obrigatório (apenas números)";
  if (value.length !== 2) return "Formato inválido";
  if (!estados.includes(value)) return "Inválido";
  return undefined;
}

const validateEmail = (value) => {
  if (!value) return "Campo obrigatório";
  if (!emailValido(value)) return "Formato inválido";
  return undefined;
}

