import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import ReactInputVerificationCode from "react-input-verification-code";
import { Grid } from '@material-ui/core';

const VerificationCodeField = ({
  input,
  onChange,
  label,
  meta,
  value,
  length,
  ...props
}) => {
  if (!input || !meta) {
    throw Error('Sorry my friend. Did you forget field from final form?')
  }

  const handleChange = event => {
    onChange && onChange(event)
    input.onChange(event)
  }

  return (
    <Grid container spacing={1}>
        <Grid item xs={12}>
        <InputLabel htmlFor="one-time-code" error={meta.error && meta.touched}>
            {label}
        </InputLabel>
        </Grid>
        <Grid item xs={12}>
        <FormControl >
            <ReactInputVerificationCode placeholder={""} length={length} onChange={handleChange} value={value} />
        <FormHelperText error>{meta.touched && meta.error}</FormHelperText>
        </FormControl>
        </Grid>
    </Grid>
  )
}

export default VerificationCodeField
