import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import Espacos from '../espacos/Espacos';
import Agenda from '../agenda/Agenda';
import HorariosDisponiveis from '../horarios-disponiveis/HorariosDisponiveis';
import Snackbar from '../../Components/Common/Snackbar';
import { fetchEspacos } from '../espacos/EspacosApi';
import Fetching from '../utils/Fetching';
import LinkUtilities from './LinkUtilities';
import { Alert } from '@material-ui/lab';
import { getEmpresa } from '../configuracoes/ConfiguracoesApi';
import { AuthContext } from '../../Components/Login';

const useStyles = makeStyles(theme => ({
  espacos: {
    marginBottom: theme.spacing(2),
  },
  warning: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  },
}));

export default (props) => {

  const classes = useStyles();

  const [view, setView] = useState("agenda");

  const [espacoSelecionado, setEspacoSelecionado] = useState();
  const [espacos, setEspacos] = useState([]);
  const [addedEspaco, setAddedEspaco] = useState();
  const [deletedEspaco, setDeletedEspaco] = useState();
  const [updatedEspacos, setUpdatedEspacos] = useState([]);
  const [dadosEmpresa, setDadosEmpresa] = useState();
  const [fetching, setFetching] = useState(false);
  const [erro, setErro] = useState();

  const authContext = useContext(AuthContext);
  const empresaId = authContext.user.id;

  const limpaErro = () => {
    setErro(undefined);
  }

  useEffect(() => {
    const fetchDadosEmpresa = async() => {
      const response = await getEmpresa(empresaId);
      if (response.ok) {
        setDadosEmpresa(response.data);
      } else {
        setErro("Erro ao buscar dados da empresa. Contate o Administrador");
        setDadosEmpresa(undefined);
      }
    }

    fetchDadosEmpresa();
  }, [empresaId]);

  useEffect(() => {
    const getEspacos = async () => {

      setFetching(true);

      const response = await fetchEspacos();
      if (response.ok) {
        const espacos = response.data;
        setEspacos(espacos);
        
        if (espacos.length > 0) {
          setEspacoSelecionado(espacos[0].id);
        } else {
          setEspacoSelecionado(undefined);
        }

      } else {
        setErro("Não foi possível buscar as quadras. Contate o Administrador");
        setEspacos([]);
      }

      setFetching(false);
    }

    getEspacos();

  }, [deletedEspaco]);

  useEffect(() => {
    if (addedEspaco) {      

      const getEspacos = async () => {

        setFetching(true);

        const response = await fetchEspacos();
        if (response.ok) {
          const espacos = response.data;
          setEspacos(espacos);
          setEspacoSelecionado(addedEspaco);
        } else {
          setErro("Não foi possível buscar as quadras. Contate o Administrador");
          setEspacos([]);
        }

        setFetching(false);
      }
    
      getEspacos();

    }
  }, [addedEspaco]);

  useEffect(() => {
    if (updatedEspacos.length > 0) {      

      const getEspacos = async () => {

        setFetching(true);

        const response = await fetchEspacos();
        if (response.ok) {
          const espacos = response.data;
          setEspacos(espacos);
        } else {
          setErro("Não foi possível buscar as quadras. Contate o Administrador");
          setEspacos([]);
        }

        setFetching(false);
      }
    
      getEspacos();

    }
  }, [updatedEspacos]);

  const viewProps = {
    espacos,
    espacoSelecionado,
    setErro,
    setView,
    fetchingEspacos: fetching,
    ...props
  }

  if (dadosEmpresa?.acesso === 'bloqueado') {
    return <Paper>
      <Alert severity='warning'>
          {"Seu acesso foi bloqueado por falta de pagamento. Regularize o pagamento e contate o administrador."}
        </Alert>
    </Paper>
  }

  if (dadosEmpresa && dadosEmpresa?.acesso !== 'gobeach') {
    return <Paper>
      <Alert severity='warning'>
          {"Acesso transferido! Acesse com o mesmo usuário e senha em "}
          <a href="https://www.reservei.online/home">{"www.reservei.online/home"}</a>
        </Alert>
    </Paper>
  }

  return <>
    <Paper className={classes.espacos}>
      <Fetching isFetching={fetching}>
        <LinkUtilities/>
        {dadosEmpresa && !dadosEmpresa.recipientId && <Alert severity='warning' className={classes.warning}>
          {"Para permitir reservas, informe seus dados bancários em "}
          <a href="/configuracoes">{"configurações"}</a>
        </Alert>}
        <Espacos
          espacos={espacos}
          espacoSelecionado={espacoSelecionado}
          setEspacoSelecionado={setEspacoSelecionado}
          setAddedEspaco={setAddedEspaco}
          setDeletedEspaco={setDeletedEspaco}
          updatedEspacos={updatedEspacos}
          setUpdatedEspacos={setUpdatedEspacos}
          setErro={setErro}
          { ...props }
        />
      </Fetching>
    </Paper>
    <Paper>
      {view === "agenda" && <Agenda { ...viewProps } />}
      {view === "horarios" && <HorariosDisponiveis { ...viewProps } />}
    </Paper>
    <Snackbar message={erro} onClose={limpaErro} />
  </>
}
