import React, { useContext } from 'react';
import { AuthContext } from '../../Components/Login';
import PlaytimeHome from './PlaytimeHome';
import CommonHome from './CommonHome';


export default () => {
  const authContext = useContext(AuthContext);
  const { playtimeId } = authContext.user;

  return playtimeId ? <PlaytimeHome/> : <CommonHome/>

}
