import moment from 'moment'

export const formatDate = date => {
  return moment(date).format('DD/MM/YYYY')
}

export default function cutString(string, size) {
  if (string.length <= size) {
    return string;
  }

  const cuttedString = string.substring(0, size);
  const spaceIndex = string.lastIndexOf(" ");

  if (spaceIndex <= 0) {
    return cuttedString + '...';
  }

  return cuttedString.substring(0, spaceIndex);

}

export const emailValido = (email) => {
  const regex = /\S+@\S+\.\S+/;
  return regex.test(email);
}