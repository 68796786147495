import React, { useState } from 'react';
import { Button, Grid, isWidthDown, makeStyles, withWidth } from '@material-ui/core';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import EditarEspacoModal from './EditarEspacoModal';
import ExclusaoModal from './ExclusaoModal';
import { AddCircle, Delete, Edit } from '@material-ui/icons';
import { addEspaco, updateEspaco } from './EspacosApi';
import { Alert } from '@material-ui/lab';

const itemEspacoCommomProps = {
  borderRadius: 5,
  marginLeft: 10,
  marginRight: 10,
  textAlign: 'center',
  fontSize: 12,
  textTransform: 'uppercase',
  marginTop: 8,
  marginBottom: 8
};

const useStyles = makeStyles(theme => ({
  espacosContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center'
  },
  warning: {
    display: 'flex',
    justifyContent: 'center',
  },
  espacoSelecionado: {
    background: theme.palette.primary.main,
    color: theme.palette.white,
    padding: 12,
    fontWeight: 'bold',
    ...itemEspacoCommomProps
  },
  espacoNaoSelecionado: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: 10,
    cursor: 'pointer',
    ...itemEspacoCommomProps
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionsSpace: {
    paddingBottom: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', 
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  icon: {
    width: '18px',
    marginRight: 5
  },
  botaoAdd: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  botaoExcluir: {
    color: theme.palette.red,
    fontSize: 10,
    fontWeight: 'bold',
  },
  collapseButtonContainer: {
    cursor: 'pointer',
  },
  container: {
    display: 'flex'
  },
  collapseIcon: {
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
}));

const ItemEspaco = (props) => {
  const classes = useStyles();

  return <div 
      onClick={props.onClick} 
      className={props.selecionada ? classes.espacoSelecionado : classes.espacoNaoSelecionado}>
    {props.nome}
  </div>
}


const Espacos = (props) => {

  const classes = useStyles();

  const { 
    espacos, 
    espacoSelecionado, 
    setEspacoSelecionado, 
    setAddedEspaco, 
    setErro,
    updatedEspacos,
    setUpdatedEspacos
  } = props;

  const [collapseOpen, setCollapseOpen] = useState(false);
  
  const shouldUseCollapse = isWidthDown('xs', props.width);
  const shouldHideOthers = shouldUseCollapse && !collapseOpen;
  const shouldShowAdd = !shouldUseCollapse || collapseOpen || espacos.length === 0;

  const [novaEspacoModalOpen, setNovaEspacoModalOpen] = useState(false);
  const [editarEspacoModalOpen, setEditarEspacoModalOpen] = useState(false);
  const [excluirEspacoModalOpen, setExcluirEspacoModalOpen] = useState(false);

  const objEspacoSelecionado = espacos.filter(q => q.id === espacoSelecionado);
  const nomeEspacoSelecionado = objEspacoSelecionado.length > 0 ? objEspacoSelecionado[0].nome : undefined;
  const valorEspacoSelecionado = objEspacoSelecionado.length > 0 ? objEspacoSelecionado[0].valor : undefined;
  const duracaoEspacoSelecionado = objEspacoSelecionado.length > 0 ? objEspacoSelecionado[0].duracao : undefined;

  const visibleEspacos = shouldHideOthers
    ? espacos.filter(q => q.id === espacoSelecionado)
    : espacos;

  const saveActionAdd = (values) => {
    addEspaco(values).then(response => {
      if (response.ok) {
        setAddedEspaco(response.data.id);
      } else {
        setErro("Não foi possível adicionar a quadra");
      }
    });
  }

  const saveActionEdit = (values) => {
    updateEspaco(espacoSelecionado, values).then(response => {
      if (response.ok) {
        setUpdatedEspacos([...updatedEspacos, response.data.id]);
      } else {
        setErro("Não foi possível editar a quadra");
      }
    });
  }

  return <>
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12}>
          {espacos.length === 0 && <Alert severity='warning' className={classes.warning}>
            {"Ainda não há espaços cadastrados. Adicione com o botão abaixo."}
          </Alert>} 
          <Grid container className={classes.espacosContainer}>
            {visibleEspacos.map(q => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={q.id}>
                <ItemEspaco
                  nome={q.nome}
                  selecionada={q.id === espacoSelecionado}
                  onClick={() => {
                    if (espacoSelecionado !== q.id) {
                      setEspacoSelecionado(q.id);
                      setCollapseOpen(false);
                    } 
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.actionsSpace}>
          {shouldShowAdd && <Grid container className={classes.actionsContainer}>
            <Grid item className={classes.actions}>
              <Button 
                className={classes.botaoAdd} 
                onClick={() => setNovaEspacoModalOpen(true)}
                color="primary"
                size="small"
              >
                <AddCircle className={classes.icon}/>
                <span>{"ADICIONAR"}</span>
              </Button>
            </Grid>
            <Grid item className={classes.actions}>
              <Button 
                className={classes.botaoAdd} 
                onClick={() => setEditarEspacoModalOpen(true)}
                color="primary"
                size="small"
              >
                <Edit className={classes.icon}/>
                <span>{"EDITAR"}</span>
              </Button>
            </Grid>
            <Grid item className={classes.actions}>
              <Button
                  className={classes.botaoExcluir}
                  onClick={() => setExcluirEspacoModalOpen(true)}
                  size="small"
              >
                <Delete className={classes.icon}/>
                <span>{"EXCLUIR"}</span>
              </Button>
            </Grid>            
          </Grid>}
        </Grid>
      </Grid>
      {shouldUseCollapse && <div
        className={classes.collapseButtonContainer}
        onClick={() => setCollapseOpen(!collapseOpen)}
      >
        <SwapHoriz className={classes.collapseIcon}/>
      </div>}
    </div>
    {novaEspacoModalOpen && <EditarEspacoModal 
      saveAction={saveActionAdd}
      modalOpen={novaEspacoModalOpen}
      setModalOpen={setNovaEspacoModalOpen}
      {...props}
    />}
    {editarEspacoModalOpen && <EditarEspacoModal 
      saveAction={saveActionEdit}
      initialValues={{
        nome: nomeEspacoSelecionado,
        valor: valorEspacoSelecionado,
        duracao: duracaoEspacoSelecionado
      }}
      modalOpen={editarEspacoModalOpen}
      setModalOpen={setEditarEspacoModalOpen}
      {...props}
    />}
    {excluirEspacoModalOpen && <ExclusaoModal
      modalOpen={excluirEspacoModalOpen}
      setModalOpen={setExcluirEspacoModalOpen}
      nomeEspacoSelecionado={nomeEspacoSelecionado}
      {...props}
    />}

  </>
}

export default withWidth()(Espacos)