import config from '../config'
import { create } from 'apisauce'

export const clienteRestApi = create({
  baseURL: config.baseURL
})

const getApi = () => {

  return {
    setToken: token => clienteRestApi.setHeader('Authorization', `Bearer ${token}`),
    ping: () => clienteRestApi.get('/ping?$limit=1'),
    forgetPassword: data => clienteRestApi.post('/solicitacao-recuperar-senha', {...data, acessoKey: 'gobeach'}),
    forgetPasswordConfirmation: data => clienteRestApi.post('/confirmacao-recuperar-senha', data),
    login: (credentials) => clienteRestApi.post('/authentication', {
      strategy: "clientes",
      ...credentials
    })
  }
}

export default getApi()
