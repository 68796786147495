import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { getAntecipacoes } from './SaldosApi';
import Fetching from '../utils/Fetching';
import { Grid, isWidthDown, isWidthUp, withWidth } from '@material-ui/core';
import moment from 'moment';
import currencyFormatter from '../utils/currencyFormatter';

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%"
  },
  row: {
    padding: theme.spacing(1)
  },
  rowOdd: { 
    backgroundColor: theme.palette.action.hover,
    padding: theme.spacing(1)
  },
  item: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: 12
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  value: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold'
  }
}));

const Antecipacoes = (props) => {

  const { setMensagem, width, version } = props;

  const [rows, setRows] = useState([]);
  const [fetching, setFetching] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const fetchAntecipacoes = async() => {

    setFetching(true);

    const response = await getAntecipacoes();
    if (response.ok) {
      setRows(response.data);
    } else {
      setMensagem("Erro ao buscar os antecipações. Contate o Administrador");
      setRows([]);
    }

    setFetching(false);
  }

  fetchAntecipacoes();

  }, [version, setMensagem]);

  const ResponsiveValue = ({ label, value }) => <div className={classes.value}>
    {isWidthDown('sm', width) && <span className={classes.label}>{label}</span>}
    {value}
  </div>

  return rows && rows.length > 0 && <Fetching isFetching={fetching}>
      <Grid container className={classes.titleContainer}>
        <span className={classes.title}>{"Antecipações Pendentes"}</span>
      </Grid>
      <Paper className={classes.paper}>
        {isWidthUp('md', width) && <Grid container className={classes.row}>
          <Grid item md={2} className={classes.item}>
            <span className={classes.label}>{"Solicitação"}</span>
          </Grid>
          <Grid item md={2} className={classes.item}>
            <span className={classes.label}>{"Pagamento"}</span>
          </Grid>
          <Grid item md={2} className={classes.item}>
            <span className={classes.label}>{"Valor"}</span>
          </Grid>
          <Grid item md={2} className={classes.item}>
            <span className={classes.label}>{"Taxa"}</span>
          </Grid>
          <Grid item md={2} className={classes.item}>
            <span className={classes.label}>{"Taxa Antecipação"}</span>
          </Grid>
          <Grid item md={2} className={classes.item}>
            <span className={classes.label}>{"Taxa do Anti-fraude"}</span>
          </Grid>      
        </Grid>}
        {rows.map((row, index) => (
          <Grid container key={row.transaction_id} className={index % 2 === 0 ? classes.rowOdd : classes.row}>
            <Grid item xs={12} sm={6} md={2} className={classes.item}>
              <ResponsiveValue 
                label="Solicitação: "
                value={moment(row.date_created).format('DD/MM/YYYY HH:mm')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className={classes.item}>
              <ResponsiveValue 
                label="Pagamento: "
                value={moment(row.payment_date).format('DD/MM/YYYY')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className={classes.item}>
              <ResponsiveValue 
                label="Valor: "
                value={currencyFormatter(row.amount / 100)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className={classes.item}>
              <ResponsiveValue 
                label="Taxa: "
                value={currencyFormatter(row.fee / 100)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className={classes.item}>
              <ResponsiveValue 
                label="Taxa Antecipação: "
                value={currencyFormatter(row.anticipation_fee / 100)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className={classes.item}>
              <ResponsiveValue 
                label="Taxa do Anti-fraude: "
                value={currencyFormatter(row.fraud_coverage_fee / 100)}
              />
            </Grid>
          </Grid>
        ))}
      </Paper>
    </Fetching>
}

export default withWidth()(Antecipacoes)