import {useContext, useEffect, useCallback} from 'react'
import apiCliente from '../../services/apiCliente'
import { ClienteContext } from './ClienteAuthProvider'

const VerifyLoginCliente = () => {
  const context = useContext(ClienteContext)

  const verifyToken = useCallback(async () => {
    const response = await apiCliente.ping()
    if (context.auth?.logged && response.status === 401) {
      context.logout()
    }
  }, [context])

  useEffect(() => {
    verifyToken()
  }, [verifyToken])
  return null
}

export default VerifyLoginCliente
