import React, { useState, useContext } from 'react'
import { Button, Grid } from '@material-ui/core';
import { TextField } from '../../Components/Fields';
import { Field, Form } from 'react-final-form';
import api from '../../services/apiCliente';
import Fetching from '../utils/Fetching';
import VerificationCodeField from '../../Components/Fields/VerificationCodeField';
import { ClienteContext } from './ClienteAuthProvider';
import validatePassword from './validationPassword';


export default (props) => {
  const { login } = useContext(ClienteContext);
  const { classes, email, setErro, onForgetPasswordClick, onRecoverPasswordSuccess, onBackClick } = props;

  const [fetching, setFetching] = useState(false);

  function validateRecoverCode(recoverCode) {
    if (!recoverCode){
      return "Obrigatório";
    }
    if (recoverCode.length !== 6) {
      return "Necessário informar 6 digitos"
    }
  }

  const validate = values => ({
    recoverCode: validateRecoverCode(values.recoverCode),
    password: validatePassword(values.password),
    confirmPassword: values.confirmPassword !== values.password ? "Senhas diferem" : undefined
  });

  const onSubmit = (values) => {
    setFetching(true);
    api.forgetPasswordConfirmation(values).then(response => {
      if (response.ok) {
        if (!response.data.accessToken) {
          setErro("Erro ao validar token")
        } else {
          login(response.data);
          api.setToken(response.data.accessToken)
          onRecoverPasswordSuccess();
        }
      } else {
        if (response.status === 422 && response.data.message) {
          setErro(response.data.message);
        }
        else {
        setErro("Erro ao recuperar conta");
        }
      }

      setFetching(false);
    })
  } 

  return <>
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{email}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container spacing={2} className={classes.formContainer}>
            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                className={classes.field}
                component={TextField}
                name="email"
                disabled={true}
                label="E-mail"
              />
            </Grid>
            
            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                className={classes.field}
                component={VerificationCodeField}
                name="recoverCode"
                disabled={true}
                length={6}
                label="Insira o código enviado no e-mail"
              />
            </Grid>

            <Grid item xs={12} >
              <span onClick={onForgetPasswordClick} className={classes.spanPrimaryText}>{"Solicitar um novo código"}</span>
            </Grid>

            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                className={classes.field}
                component={TextField}
                type="password"
                name="password"
                label="Nova Senha"
              />
            </Grid>

            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                className={classes.field}
                component={TextField}
                type="password"
                name="confirmPassword"
                label="Confirme a Nova Senha"
              />
            </Grid>

            <Grid item xs={12} className={classes.submitContainer}>
              <Fetching isFetching={fetching}>
                <Button
                  variant="outlined"
                  className={classes.backButton}
                  onClick={onBackClick}
                >
                  {"VOLTAR"}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.loginButton}
                >
                  {"ATUALIZAR SENHA"}
                </Button>
              </Fetching>
            </Grid>
          </Grid>
        </form>)} />
  </>
}