import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, IconButton, makeStyles, Paper } from "@material-ui/core";
import Fetching from '../utils/Fetching';
import Snackbar from '../../Components/Common/Snackbar';
import { getSaldos } from './SaldosApi';
import currencyFormatter from '../utils/currencyFormatter';
import { Help } from '@material-ui/icons';
import HelpSaldoModal from './HelpSaldoModal';
import { getEmpresa } from '../configuracoes/ConfiguracoesApi';
import { AuthContext } from '../../Components/Login/Auth';
import Recebiveis from './Recebiveis';
import AntecipacaoModal from './AntecipacaoModal';
import Antecipacoes from './Antecipacoes';
import Transferencias from './Transferencias';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  labelGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  labelSaldo: {
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  valorGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  anteciparGrid: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2)
  },
  valores: {
    color: theme.palette.primary.main
  },
  button: {
    marginLeft: theme.spacing(2)
  },
  helpButton: {
    width: 15,
    height: 15
  },
  containerSemDadosBancarios: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  textoSemDadosBancarios: {
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  linkConfiguracoes: {
    fontSize: 12,
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: 5
  },
}));

export default (props) => {

  const classes = useStyles();
  const { history } = props;
  
  const authContext = useContext(AuthContext);
  const empresaId = authContext.user.id;

  const [version, setVersion] = useState();

  const [saldos, setSaldos] = useState();
  const [recipient, setRecipient] = useState();

  const [fetching, setFetching] = useState(false);
  const [mensagem, setMensagem] = useState();

  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [antecipacaoModalOpen, setAntecipacaoModalOpen] = useState(false);

  const limpaMensagem = () => {
    setMensagem(undefined);
  }

  useEffect(() => {
    const fetchDadosEmpresa = async() => {

      setFetching(true);

      const response = await getEmpresa(empresaId);
      if (response.ok) {
        setRecipient(response.data.recipientId);
      } else {
        setMensagem("Erro ao buscar dados da academia. Contate o Administrador");
        setRecipient(undefined);
      }

      setFetching(false);
    }

    fetchDadosEmpresa();
  }, [empresaId]);

  useEffect(() => {
    const fetchSaldos = async() => {

      setFetching(true);

      const response = await getSaldos();
      if (response.ok) {
        setSaldos(response.data);
      } else {
        setMensagem("Erro ao buscar o saldo. Contate o Administrador");
        setSaldos({});
      }

      setFetching(false);
    }

    fetchSaldos();
  }, [version]);

  const HelpIcon = () => (
    <IconButton
      onClick={() => setHelpModalOpen(true)}
      className={classes.helpButton}
    >
      <Help className={classes.helpButton}/>
    </IconButton>);

  return <>
    <Paper>
      <Fetching isFetching={fetching}>
        {!recipient && <Grid container className={classes.containerSemDadosBancarios}>
          <span className={classes.textoSemDadosBancarios}>
            {"Para ter acesso a este painel, configure seus dados bancários em"}
          </span>
          <span className={classes.linkConfiguracoes} onClick={() => history.push('/configuracoes')}>
            {"Configurações"}
          </span>
        </Grid>}
        {recipient && saldos && <Grid container className={classes.mainContainer}>
          <Grid item xs={6} className={classes.labelGrid}>
            <span className={classes.labelSaldo}>{"Saldo Disponível"}</span>
            <HelpIcon/>
          </Grid>
          <Grid item xs={6} className={classes.valorGrid}>
            <span className={classes.valor}>{currencyFormatter(saldos.saldoDisponivel)}</span>
          </Grid>
          <Grid item xs={6} className={classes.labelGrid}>
            <span className={classes.labelSaldo}>{"Saldo a Receber"}</span>
            <HelpIcon/>
          </Grid>
          <Grid item xs={6} className={classes.valorGrid}>
            <span className={classes.valor}>{currencyFormatter(saldos.saldoReceber)}</span>
          </Grid>
          <Grid item xs={6} className={classes.labelGrid}>
            <span className={classes.labelSaldo}>{"Valor Antecipável"}</span>
            <HelpIcon/>
          </Grid>
          <Grid item xs={6} className={classes.valorGrid}>
            <span className={classes.valor}>{currencyFormatter(saldos.saldoAntecipavel)}</span>
          </Grid>
          <Grid item xs={12} className={classes.anteciparGrid}>
            <Button 
              color="primary"
              size="small"
              variant="contained"
              className={classes.button}
              onClick={()=> setAntecipacaoModalOpen(true)}
            >
              {"Antecipar"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Antecipacoes 
              setMensagem={setMensagem}
              version={version}/>
          </Grid>
          <Grid item xs={12}>
            <Transferencias 
              setMensagem={setMensagem}/>
          </Grid>
          <Grid item xs={12}>
            <Recebiveis 
              setMensagem={setMensagem}/>
          </Grid>
        </Grid>}
        
      </Fetching>
    </Paper>
    <HelpSaldoModal
      modalOpen={helpModalOpen}
      setModalOpen={setHelpModalOpen}
    />
    <AntecipacaoModal 
      modalOpen={antecipacaoModalOpen}
      setModalOpen={setAntecipacaoModalOpen}
      setMensagem={setMensagem}
      version={version}
      setVersion={setVersion}
    />
    <Snackbar message={mensagem} onClose={limpaMensagem} />
  </>
}