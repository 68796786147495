import React, { useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./Theme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import api from "./services/api";
import { AuthorizationContext } from "./modules/users/abilities";
import { withStyles } from "@material-ui/core";
import { Auth as AuthProvider } from "./Components/Login"
import AppGlobalRouter from "./Router/AppGlobalRouter";
import moment from 'moment'
import 'moment/locale/pt-br'

const styles = () => ({
  "@global": {
   
  },
});

const App = () => {
  
  moment.locale('pt-br');

  const [ability, setAbility] = useState();

  const withUser = async user => {
    await api.setToken(user.token);
  };

  return (
    <ThemeProvider theme={theme}>
      <AuthorizationContext.Provider value={ability}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthProvider
            withUser={withUser}
            onLogout={() => {
              setAbility(null);
            }}
          >
            <AppGlobalRouter/>
          </AuthProvider>
        </MuiPickersUtilsProvider>
      </AuthorizationContext.Provider>
    </ThemeProvider>
  );
};

export default withStyles(styles)(App);
