import React, { useMemo, useState } from 'react';
import {
    Scheduler,
    Appointments,
    Toolbar,
    DateNavigator
  } from '@devexpress/dx-react-scheduler-material-ui';
import { isWidthUp, makeStyles, withWidth } from '@material-ui/core';
import { ViewState } from '@devexpress/dx-react-scheduler';
import ToolbarSpace from './ToolbarSpace';
import DetailModal from './DetailModal';
import moment from 'moment';

const DIAS_SEMANA_LABEL = {
  1: 'SEGUNDA-FEIRA',
  2: 'TERÇA-FEIRA',
  3: 'QUARTA-FEIRA',
  4: 'QUINTA-FEIRA',
  5: 'SEXTA-FEIRA',
  6: 'SÁBADO',
  7: 'DOMINGO',
}

const useStyles = makeStyles(theme => ({
  aberto: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  pago: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  }
}));

const Appointment = ({children, setModalDetailOpen, setDataModal, ...restProps}) => {
  const classes = useStyles();
  const { data } = restProps;
  
  return <Appointments.Appointment
      {...restProps}
      className={data.status === 'PAGO' ? classes.pago : classes.aberto}
      onClick={() => {
        setDataModal(data);
        setModalDetailOpen(true);
      }}
    >
      {children}
    </Appointments.Appointment>
}
  
const NavigatorButton = (props) => {
  if (isWidthUp('md', props.width)) {
    return <DateNavigator.NavigationButton {...props}>
        {props.children}
      </DateNavigator.NavigationButton>
  }

  return ""
}

const getDescricaoOcupacao = (ocupacao) => {
  if (ocupacao.tipo === 'INDISPONIVEL') {
    return ocupacao.descricao || "Agenda Ocupada";
  }
  if (ocupacao.tipo === 'AGENDAMENTO') {
    return `${ocupacao.cliente} (${ocupacao.codigo})`;
  }
  return `(${ocupacao.status}) ${ocupacao.descricao || "Reserva Manual"}`;
}

export default (props) => {

  const horariosSemanais = useMemo(() => {
    if (!props.horariosSemanais.length) {
      return [];
    }

    const horariosPorWeekday = props.horariosSemanais.reduce((acc, cur) => {
      if (acc[cur.diaSemana]) {
        acc[cur.diaSemana].push(cur);
      } else {
        acc[cur.diaSemana] = [cur];
      }
      return acc;
    }, {})
    const result = [];
    let currentDate = moment().subtract(2, 'weeks');
    while (currentDate.isBefore(moment().add(2, 'months'))) {
      for (const horarioSemanal of (horariosPorWeekday[currentDate.isoWeekday()] || [])) {
        const horarioInicio = moment(horarioSemanal.horario);
        const horarioFim = moment(horarioSemanal.horarioFim);
        result.push({
          id: horarioSemanal.id,
          startDate: moment(currentDate)
            .hour(horarioInicio.hour())
            .minute(horarioInicio.minute())
            .startOf('minute')
            .toISOString(),
          endDate: moment(currentDate)
            .hour(horarioFim.hour())
            .minute(horarioFim.minute())
            .startOf('minute')
            .toISOString(),
          title: horarioSemanal.descricao || 'Horário Semanal',
          subtitle: `${DIAS_SEMANA_LABEL[currentDate.isoWeekday()]} ${moment(horarioInicio).format('HH:mm')}`,
          tipo: 'HORARIO_SEMANAL',
          espacoId: horarioSemanal.espacoId,
        })
      }
      currentDate = currentDate.add(1, 'day');
    }

    return result;

  }, [props.horariosSemanais]);

  const schedulerData = useMemo(() => [
    ...props.ocupacoesAgenda.map((o => {
      return {
        id: o.id,
        startDate: o.dataHora,
        endDate: o.dataHoraFim,
        title: getDescricaoOcupacao(o),
        subtitle: o.telefone,
        tipo: o.tipo,
        status: o.status,
        limiteCancelamento: o.limiteCancelamento,
        qrCode: o.qrCode,
        espacoId: o.espacoId,
      }
    })),
    ...horariosSemanais,
    ...props.diasOcupados.map(d => {
      return {
        id: d.id,
        startDate: moment(d.dia).startOf('day'),
        endDate: moment(d.dia).endOf('day'),
        title: d.descricao || 'Dia ocupado',
        subtitle: moment(d.dia).format('DD/MM/YYYY'),
        tipo: 'DIA_OCUPADO',
        espacoId: d.espacoId,
      }
    })
  ], [props.ocupacoesAgenda, props.diasOcupados, horariosSemanais]);

  const [modalDetailOpen, setModalDetailOpen] = useState(false);
  const [dataModal, setDataModal] = useState({});

  const ToolbarComponent = (componentProps) => ToolbarSpace({ ...props, ...componentProps });
  const ApointmentComponent = (componentProps) => Appointment({ setModalDetailOpen, setDataModal, ...componentProps });

  const { selectedDate,  setSelectedDate } = props;

  return <>
    <Scheduler data={schedulerData}>
      <ViewState 
        currentDate={selectedDate}
        onCurrentDateChange={(date) => setSelectedDate(date)}
      />
        {props.children}
      <Toolbar flexibleSpaceComponent={withWidth()(ToolbarComponent)}/>
      <DateNavigator 
        navigationButtonComponent={withWidth()(NavigatorButton)}
      />
      <Appointments appointmentComponent={ApointmentComponent} />
    </Scheduler>      
    <DetailModal 
      modalOpen={modalDetailOpen}
      setModalOpen={setModalDetailOpen}
      data={dataModal}
      {...props}
    />
  </>;
}