import React, { useState } from 'react';
import { Button, Grid, makeStyles, MenuItem, Modal, Select, TextField } from '@material-ui/core';
import moment from 'moment'
import { addOcupacaoSemanal } from './AgendaApi';
import Fetching from '../utils/Fetching';
import { TimePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  modal: {
    background: theme.palette.white,
    position: 'absolute',
    margin: 'auto',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '330px',
    height: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    borderRadius: 20
  },
  container: {
    width: '100%'
  },
  labelGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: 11,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    padding: theme.spacing(2)
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2)
  },
  selectText: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  field: {
    width: '100%',
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  }
}));

export default (props) => {

  const classes = useStyles();

  const [horario, setHorario] = useState(moment().startOf('hour'));
  const [espaco, setEspaco] = useState(props.espacoSelecionado);
  const [diaSemana, setDiaSemana] = useState(1);
  const [descricao, setDescricao] = useState();
  const diasSemanaOptions = [
    { weekday: 1, label: 'SEGUNDA-FEIRA' },
    { weekday: 2, label: 'TERÇA-FEIRA' },
    { weekday: 3, label: 'QUARTA-FEIRA' },
    { weekday: 4, label: 'QUINTA-FEIRA' },
    { weekday: 5, label: 'SEXTA-FEIRA' },
    { weekday: 6, label: 'SÁBADO' },
    { weekday: 7, label: 'DOMINGO' },
  ];

  const [fetching, setFetching] = useState(false);

  const { versionHorariosSemanais, setVersionHorariosSemanais, setErro } = props;

  const handleAddOcupacao = () => {
    setFetching(true);
    addOcupacaoSemanal(espaco, diaSemana, horario, descricao).then(response => {
      if (response.ok) {
        setVersionHorariosSemanais(versionHorariosSemanais + 1);
      } else {
        setErro("Não foi possível adicionar a ocupação. Contate o Administrador");
      }
    })    
  }
  
  return <Modal
    open={props.modalOpen}
    onClose={() => props.setModalOpen(false)}
  >
    <div className={classes.modal}>
      <Fetching isFetching={fetching}> 
        <Grid container className={classes.container}>
          <Grid item xs={4} className={classes.labelGrid}>
            <span>{"ESPAÇO"}</span>
          </Grid>
          <Grid item xs={7} className={classes.center}>
            <Select
              value={espaco}
              onChange={(event) => setEspaco(event.target.value)}
              className={classes.field}
            >
              {props.espacos && props.espacos.map(q => (
                <MenuItem value={q.id} key={q.id} className={classes.selectText}>
                  {q.nome}
                </MenuItem>))}
            </Select>
          </Grid>
          <Grid item xs={4} className={classes.labelGrid}>
            <span>{"DIA SEMANA"}</span>
          </Grid>
          <Grid item xs={7} className={classes.center}>
            <Select
              value={diaSemana}
              onChange={(event) => setDiaSemana(event.target.value)}
              className={classes.field}
            >
              {diasSemanaOptions.map(d => (
                <MenuItem value={d.weekday} key={d.weekday} className={classes.selectText}>
                  {d.label}
                </MenuItem>))}
            </Select>
          </Grid>
          <Grid item xs={4} className={classes.labelGrid}>
            <span>{"HORÁRIO"}</span>
          </Grid>
          <Grid item xs={7} className={classes.center}>
            <TimePicker
              className={classes.hour}
              value={horario}
              onChange={setHorario}
              ampm={false}
              minutesStep={15}
              autoOk
              views={['hours', 'minutes']}
              />
          </Grid>
          <Grid item xs={4} className={classes.labelGrid}>
            <span>{"DESCRIÇÃO"}</span>
          </Grid>
          <Grid item xs={7} className={classes.center}>
            <TextField
              value={descricao}
              onChange={(event) => setDescricao(event.target.value)}
              className={classes.field}
              placeholder="(opcional)"
            />
          </Grid>
          <Grid item xs={12} className={classes.buttonGrid}>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={handleAddOcupacao}
            >
              {"SALVAR"}
            </Button>
          </Grid>
        </Grid>
      </Fetching>
    </div>
  </Modal>
}