import React from 'react'
import { ClienteProvider } from '../login/ClienteAuthProvider'
import VerifyLoginCliente from '../login/VerifyLoginCliente'
import Reserva from './Reserva'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel';
import { v4 as uuid_v4 } from "uuid";

export default (props) => {

  ReactGA.initialize('UA-197571284-2');
  ReactGA.set({
    userId: uuid_v4(),
  })
  ReactGA.pageview(window.location.pathname + window.location.search);
  
  ReactPixel.init('183399377069105');
  ReactPixel.pageView();

  return <ClienteProvider>
    <Reserva {...props} />
    <VerifyLoginCliente />
  </ClienteProvider>
}