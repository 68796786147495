import React from 'react'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import { Login, RecoverPassword, ResetPassword } from '../modules/login'
import { AppLoggedRouter } from './AppLoggedRouter'
import { PrivateRoute, loginRoutes } from "./../Components/Login"
import CancelarReserva from '../modules/reserva/CancelarReserva'
import ReservaScreen from '../modules/reserva/ReservaScreen'
import Registry from '../modules/cadastro/Registry'

export default function AppGlobalRouter() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path={loginRoutes.login} component={Login} />
                <Route
                    path={loginRoutes.recoverPassword}
                    component={RecoverPassword}
                />
                <Route
                    path='/resetar-senha'
                    component={ResetPassword}
                />
                <Route
                    path='/em/:id'
                    component={ReservaScreen}
                />
                <Route
                    path='/cancelar-reserva'
                    component={CancelarReserva}
                />
                <Route
                    path='/criar-conta'
                    component={Registry}
                />
                <PrivateRoute path="/" component={AppLoggedRouter} />
            </Switch>
        </BrowserRouter>
    )
}
