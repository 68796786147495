import React, { useState } from 'react';
import { Button, Grid, makeStyles, Modal } from '@material-ui/core';
import Fetching from '../utils/Fetching';
import { addAntecipacao } from './SaldosApi';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

const useStyles = makeStyles(theme => ({
  modal: {
    background: theme.palette.white,
    position: 'absolute',
    margin: 'auto',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '250px',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    borderRadius: 20
  },
  container: {
    width: '100%'
  },
  obs: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  fieldContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  field: {
    width: 100
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: theme.spacing(2)
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.white,
    '&:hover': {
      background: theme.palette.secondary.main,
    },
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  }
}));

export default (props) => {

  const classes = useStyles();

  const [valor, setValor] = useState();
  const [fetching, setFetching] = useState(false);

  const { setMensagem, modalOpen, setModalOpen, version, setVersion } = props;

  const handleConfirmaAntecipacao = () => {
    setFetching(true);
    addAntecipacao(valor).then(response => {
      if (response.ok) {
        setMensagem("Antecipação solicitada com sucesso.");
        setVersion(version + 1)
      } else {
        setMensagem(response.data?.message || "Erro ao solicitar antecipação");
      }
    });
    setFetching(false);
    setModalOpen(false);
  }
  
  return <Modal
    open={modalOpen}
    onClose={() => setModalOpen(false)}
  >
    <div className={classes.modal}>
      <Fetching isFetching={fetching}>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.obs}>
            <span>{'Atenção! É cobrada uma taxa de 2,20% pela operadora na antecipação.'}</span>
          </Grid>
          <Grid item xs={12} className={classes.fieldContainer}>
            <CurrencyTextField
              className={classes.field}
              label="Valor"
              variant="standard"
              value={valor}
              currencySymbol="R$"
              outputFormat="number"
              decimalCharacter=","
              digitGroupSeparator="."
              onChange={(event, value) =>
                setValor(value)
              }
            />
          </Grid>
          
          <Grid item xs={12} className={classes.buttonGrid}>
            <Button
              className={classes.button}
              size="small"
              onClick={handleConfirmaAntecipacao}
            >
              {"CONFIRMAR"}
            </Button>
            <Button
              color="secondary"
              size="small"
              onClick={() => setModalOpen(false)}
            >
              {"CANCELAR"}
            </Button>
          </Grid>
        </Grid>
      </Fetching>
    </div>
  </Modal>
}